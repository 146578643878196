import * as React from "react"
import { graphql } from "gatsby"
import moment from "moment"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"



function truncateAddress(address) {
  if (!!address === false) return '';
  return address.substring(0,4) + '...' + address.substring(address.length-4);
}

const CurrentMarketStatus = ({ currentOwner, listingMarket, mintAddress, waveName }) => {
  if (!!currentOwner === false) return null;

  const marketLinks = {
    'Alpha.art': 'https://alpha.art/collection/playground-waves/',
    'Magic Eden': 'https://magiceden.io/marketplace?collection_symbol=playground_waves',
    Solanart: 'https://solanart.io/collections/playground'
  }

  return (
    <section className='market-status'>
      <h2>Current Market Status</h2>
      <p>{waveName} is currently owned by <a href={'https://alpha.art/user/' + currentOwner}>{currentOwner}</a></p>
      {listingMarket == 'unlisted' ?
        <p>{waveName} is not currently listed for sale.</p>
        : <p>{waveName} is currently listed for sale on {listingMarket}.</p>
      }
      <p>
        {listingMarket !== 'unlisted' &&
          <a href={marketLinks[listingMarket]}>Buy Now</a>
        }
        <a href={'https://alpha.art/t/' + mintAddress}>Make an Offer</a>
      </p>
    </section>
  )
}

const TransactionsSection = ({ transactionHistory }) => {
  if (transactionHistory.length == 0) return null;

  const transactionTranslate = {
    cancelEscrow: 'Delist',
    initializeEscrow: 'Listing',
    exchange: 'Sale',
    LISTING: 'Listing',
    UNLIST: 'Delist'
  }
  const marketTranslate = {
    'alpha.art': 'Alpha.art',
    magiceden: 'Magic Eden',
    solanart: 'Solanart',
    'solanart.io': 'Solanart',
    'digitaleyes.market': 'Digital Eyes'
  }
  
  return (
      <section className='transaction-history'>
      <h2>Transaction History</h2>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>From</th>
            <th>To</th>
            <th>Amount</th>
            <th>Marketplace</th>
            <th>Timestamp</th>
            <th>TxID</th>
          </tr>
        </thead>
        <tbody>
          
          {transactionHistory.map((tx) => (
            <tr key={tx.transactionId}>
              <td>{transactionTranslate[tx.transactionType]}</td>
              <td><a href={'https://explorer.solana.com/address/' + tx.seller}>{truncateAddress(tx.seller)}</a></td>
              <td><a href={'https://explorer.solana.com/address/' + tx.buyer}>{truncateAddress(tx.buyer)}</a></td>
              {!!tx.amount ?
              <td style={{ minWidth: '140px'}}>{(tx.amount * 0.000000001).toFixed(1)} SOL</td> :
              <td></td>
              }
              <td>{marketTranslate[tx.source]}</td>
              <td style={{ minWidth: '190px'}}>{moment(tx.timestamp).format('MMM Do \'YY')}</td>
              <td><a href={'https://explorer.solana.com/tx/' + tx.transactionId}>{truncateAddress(tx.transactionId)}</a></td>
            </tr>
          ))}
        </tbody>
      </table>
      </section>
  )
}

const EpochDetail = ({ data }) => {
  const [chainData, setChainData] = React.useState({});
  const [transactionHistory, setTransactionHistory] = React.useState([]);
  const [currentOwner, setCurrentOwner] = React.useState('');
  const [listingMarket, setListingMarket] = React.useState('unlisted');

  const activityURL = 'https://apis.alpha.art/api/v1/activity';
  const alphaPayload = {
    //id: data.epochsJson.properties.mint_address,
    limit: 20,
    resourceType: 'TOKEN',
    tradingTypes: [
      'LISTING',
      'SALE',
      'OFFER',
      'UNLIST'
    ]
  };
  //const alphaTokenURL = 'https://apis.alpha.art/api/v1/token/' + data.epochsJson.properties.mint_address;
  const marketWallets = {
    '3D49QorJyNaL4rcpiynbuS3pRH4Y7EXEM6v6ZGaqfFGK': 'Solanart',
    '4pUQS4Jo2dsfWzt3VgHXy3H6RYnEDd11oWPiaM2rdAPw': 'Alpha.art',
    'GUfCR9mK6azb9vcpsxgXyj7XRPAKJd4KMHTTVvtncGgp': 'Magic Eden'
  }
  //const magicNFTInfoURL = 'https://api-mainnet.magiceden.io/rpc/getNFTByMintAddress/' + data.epochsJson.properties.mint_address;
  //const transactionURL = 'https://api-mainnet.magiceden.io/rpc/getGlobalActivitiesByQuery?q={%22%24match%22%3A{%22mint%22%3A%22' + data.epochsJson.properties.mint_address + '%22}%2C%22%24sort%22%3A{%22blockTime%22%3A-1}%2C%22%24skip%22%3A0}';

  React.useEffect(() => {
    console.log(data.epochsJson);
  }, []);

  let epochNum = data.epochsJson.name.split(' #')[1];
  let mobilePicUrl =  "https://d28a5q050a9bu1.cloudfront.net/images/small-site-epochs/smaller-website-sizes/" + epochNum + ".png";
  let bigPicUrl =  "https://d28a5q050a9bu1.cloudfront.net/images/real-epochs/real-epoch-images/" + epochNum + ".png";
  let liveUrl = "/epoch-" + epochNum + "-live";
  const hugeVidUrl = "https://d28a5q050a9bu1.cloudfront.net/videos/epoch-animations/" + epochNum + '+Epoch+Video.mov';
  

  return (
    <Layout>
      <Seo title={data.epochsJson.name} />
      <section className='section-title white collection'>
        <div className='container clearfix'>
          <div className='col40 left artwork-info'>
            <div className='clearfix tags'>
              <Link to='/epochs-gallery/' className='button black left'>back to gallery</Link>
              <a href={liveUrl} className='button black left' target='_blank'>Live Animation</a>
            </div>
            <h1>{data.epochsJson.name}</h1>
            <p className='big'>{data.epochsJson.description}</p>
            <div className='details'>
              <a className='button transparent'>Generative art</a>
              <a className='button transparent'>Created via p5.js</a>
              <a className='button transparent'>3000x3000</a>
              <a className='button transparent'>Edition of 1000</a>
            </div>

            <div className='features'>
              <h4>Features</h4>
              <ul>
                <li>Seed: {data.epochsJson.seed}</li>
                <li>Cluster Type: {data.epochsJson.cluster_type}</li>
                <li>Node Style: {data.epochsJson.node_styles}</li>
                <li>Neighborhood Assignment: {data.epochsJson.neighborhood_assignment}</li>
                <li>Min. Depth: {data.epochsJson.turbine_depth_minimum}</li>
                <li>Max Depth: {data.epochsJson.turbine_depth_maximum}</li>
                <li>Colors: {data.epochsJson.colors}</li>
                <li>Scheduled Rotation: {data.epochsJson.scheduled_rotation}</li>
              </ul>

            </div>

          </div>
          <div className='col60 left image'>
            <video autoPlay playsInline muted loop poster={bigPicUrl}>
              <source src={hugeVidUrl} type="video/mp4" />
            </video>
          </div>
        </div>
      </section>

      <CurrentMarketStatus
        currentOwner={currentOwner}
        listingMarket={listingMarket}
        mintAddress=''
        waveName={data.epochsJson.name}
      />
      <TransactionsSection
        transactionHistory={transactionHistory}
      />
    </Layout>
  )
}

export const query = graphql`
  query EpochQuery($id: String) {
    epochsJson(id: {eq: $id}) {
      id
      name
      description
      cluster_type
      colors
      neighborhood_assignment
      node_styles
      scheduled_rotation
      seed
      shadow_blur_level
      shape
      show_tiles_at_depth
      turbine_depth_maximum
      turbine_depth_minimum
    }
  }
`

export default EpochDetail
